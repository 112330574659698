<template>
  <v-container>
    {{ $route.name }}
  </v-container>
</template>

<script>
export default {
  name: "TransactionsView",
};
</script>

<style></style>
